/* FONT */
@font-face {
    font-family: 'Happiness';
    src: url('../assets/fonts/Happiness/Happiness.eot?#iefix') format('embedded-opentype'),  url('../assets/fonts/Happiness/Happiness.woff') format('woff'), url('../assets/fonts/Happiness/Happiness.ttf')  format('truetype'), url('../assets/fonts/Happiness/Happiness.svg#HappinessBeta') format('svg');
    font-weight: normal;
    font-style: normal;
}

$fontHappi : 'Happiness';
$font: 'Trebuchet MS';

/* COLOR */
$black: #000000;
$white: #ffffff;
$darkGrey: #504747;
$grey: #a5a5a5;
$lightGrey: #f8f8f8;
$greyTextModal:#737373;
$shadow-light: rgba(0, 0, 0, 0.1);
$yellow: #f4b643;
$whitetransp: rgba(255, 255, 255, 0.95);
$blacktransp: rgba(0,0,0,0.6);
$red: #B50900;

/* SIZE */
$desktop-up: 1024px;
$mid-tablet: 900px;
$tablet-up: 768px;