.header-home-block{
    color: $white;
    .header-home-bkg{
        width: 100%;
        height: 365px;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        background-position: center;
        @media screen and (max-width: 420px) {
            height: 295px;
        }   
        @media screen and (min-width: $mid-tablet) {
            height: 425px;
        }
    }
    .title-block{
        position: absolute;
        z-index: 5;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 45%;
        transform: translateY(-50%);
    }
    .cloche-picto{
        height: 100px;
        width: 100px;
        background-size: contain!important;
        background-repeat: no-repeat!important;
        background-position: center!important;
        margin: auto;
        filter: brightness(0) invert(1);
        @media screen and (min-width: $mid-tablet) {
        
        }
    }
    .title{
        font-size: 42px;
        font-weight: bold;
        text-align: center;
        @media screen and (min-width: $mid-tablet) {
        }
    }
}