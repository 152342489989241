.page-home{
    padding-bottom: 50px;
    .content{
        width: 87%;
        margin: auto;
    }
    .description-home{
        text-align: left;
        margin-top: 55px;
        color: $grey;
        font-size: 24px;
        line-height: 1.22;   
        letter-spacing: 0.6px; 
    }
}