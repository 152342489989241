.menu-language-block{
    max-width: 50px;
    position: absolute;
    z-index: 6; // because of the headerhome
    right: 48px;
    top: 20px;
    text-transform: capitalize;
    transition: 0.5s;
    overflow: hidden;
    &.hide{
        max-height: 25px;
    }
    &.show{
        max-height: 100px;
    }
    .block-langage{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .arrow-lang{
        height: 15px;
        width: 15px;
        background-image: url("../assets/img/arrow_lang.png");
        background-size: contain!important;
        background-repeat: no-repeat!important;
        background-position: center!important;
    }
    .current-lang{
        margin-left: 5px;
        font-size: 22px;
    }
    .menu-language{
        padding: 0;
        margin: 0;
        text-align: left;
        max-width: 30px;
        margin-left: 20px;
        li{
        font-size: 22px;
        list-style: none;
        }
    }
}