.menu-product-block{
    margin-top: 102px;
    @media screen and (max-width: 420px) {
        margin-top: 50px;
    }
    .title-menu-product{
        font-weight: bold;
        font-size: 33px;
        color: $darkGrey; 
        padding-left: 65px;
        @media screen and (max-width: 420px) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .menu-info{
        margin-bottom: 80px;
    }
    .menu-info-general{
        
    }
    .details-block{
        margin: auto;
        margin-top: 75px;
        max-width: 510px;
        @media screen and (max-width: 420px) {
            margin-top: 50px;
            padding: 10px;
        }
    }
    .choice-block{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 13px;
    }
    .delivery-time{
        
    }
    .time-select{

    }
    .label{
        font-size: 23px;
        color: $darkGrey;
        margin-right: 90px; 
        letter-spacing: 1px;
        @media screen and (max-width: 420px) {
            margin-right: 25px;
        }
    }
    .picto-prod-type{
        position: absolute;
        width: 50px;
        height: 50px;
        left: -76px;
        top: -15px;
        filter: brightness(0) invert(0.65);
    }
    .title-prod-type{
        max-width: 520px;
        margin: auto;
        color: $grey;
        font-size: 25px;
        position: relative;
        padding-bottom: 55px;
        @media screen and (max-width: 420px) {
            padding-bottom: 20px;
        }
    }
    .number-select{
        display: flex;
        align-items: center;
    }
    .number{
        width: 90px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $darkGrey;
        font-size: 24px;
        @media screen and (max-width: 420px) {
            width: 50px;
        }
    }
    .btn-number{
        width: 45px;
        height: 45px;
        background-size: contain;
        background-repeat: no-repeat;
        &.btn-moins{
            background-image: url("../assets/img/btn-moins.png");
        }
        &.btn-plus{
            background-image: url("../assets/img/btn-plus.png");
        }
    }
    .submit-block{
        margin-top: 155px;
        @media screen and (max-width: 420px) {
            margin-top: 50px;
        }
    }
    .btn-submit{
        width: 160px;
        height: 55px;
        font-size: 28px;
        margin: auto;
    }
    .dots{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-top: 80px;
        @media screen and (max-width: 420px) {
            margin-top: 50px;
        }
        div{
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background-color: $grey;
            &:nth-child(1){
                background-color: $yellow;
                margin-left: -5px;
            }
            &:nth-child(2){
                margin-left: 6px;
            }
        }
    }
    .delivery-time-block{
        position: relative;
        &:after{
            content:"";
            background-image: url("../assets/img/arrow-select.png");
            width: 20px;
            height: 20px;
            display: block;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top: 60%;
            transform: translateY(-50%);
            right: 6px;
        }
        .delivery-time-select{
            width: 215px;
            height: 55px;
            font-size: 26px;
            background: none;
            border: none;
            color: $darkGrey;
            outline: none;
            -webkit-appearance: none;
            appearance: none;  
            padding-left: 5px; 
        }
    }
    .menu-info-product{
        .title-menu-product{
            margin-bottom: 85px;
            @media screen and (max-width: 420px) {
                margin-bottom: 30px;
            }
        }
        .details-block{
            margin-top: 68px;
            @media screen and (max-width: 420px) {
                margin-top: 30px;
            }
        }
    }
}