.page-commande{
    padding-bottom: 100px;
    position: relative;
    height: 100%;
    @media screen and (max-width: 420px) {
        padding-bottom: 50px;
    }
    .commande-block-page{
        padding-top: 100px;
        max-width: 65%;
        margin: auto;
        @media screen and (max-width: 420px) {
            padding-top: 50px;
            max-width: 90%;
        }
    }
    .title-page{
        color: $darkGrey;
        font-size: 33px;
        font-weight: bold;
    }
    .delivery-commande-block{
        margin-top: 90px;
    }
    .title-delivery-block{
        color: $grey;
        display: flex;
        justify-content: space-between;
    }
    .title-delivery-com{
        font-size: 25px;
        font-weight: bold;
    }
    .modify-delivery-com{
        text-decoration: underline;
        display: flex;
        font-size: 25px;
        &:before{
            content:"";
            width: 22px;
            height: 22px;
            background-image: url("../assets/img/pen.png");
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 15px;
        }
    }
    .block-info-com{
        margin-top: 75px;
        .info-com{
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            margin-bottom: 35px;
        }
        .label-com{
            color: $darkGrey;
            font-size: 25px;
        }
        .value-com{
            color: $grey;
            font-size: 23px;
        }
    }
    .order-commande-block{
        margin-top: 110px;
    }
    .submit-block{
        margin-top: 155px;
        @media screen and (max-width: 420px) {
            margin-top: 50px;
        }
    }
    .btn-submit{
        width: 280px;
        height: 55px;
        font-size: 28px;
        margin: auto;
        @media screen and (max-width: 420px) {
            font-size: 20px;
        }
    }
    .modal-confirm-commande{
        width: 100%;    
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $blacktransp;
        z-index: 10;
        &.hide{
            display: none;
            opacity: 0;
        }
        &.show{
            display: block;
            opacity: 1;
        }
        .content-modal{
            max-width: 365px;
            background-color: $white;
            border-radius: 18px;
            position: fixed;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin: 0 auto;
            padding: 70px 100px 50px;
            text-align: center;
            @media screen and (max-width: 420px) {
                margin: 5px;
                padding: 50px 30px 30px;
            }

            &.valid-command{
                &.hide{
                    display: none;
                    opacity: 0;
                }
                &.show{
                    display: block;
                    opacity: 1;
                }
            }
            &.error-modal{
                .title-modal{
                    color: $red;
                }
                &.hide{
                    display: none;
                    opacity: 0;
                }
                &.show{
                    display: block;
                    opacity: 1;
                }
            }
            &.error-same-menu-room{
                &.hide{
                    display: none;
                    opacity: 0;
                }
                &.show{
                    display: block;
                    opacity: 1;
                }
            }
            &.error-room{
                &.hide{
                    display: none;
                    opacity: 0;
                }
                &.show{
                    display: block;
                    opacity: 1;
                }
            }
        }
        .close-modal{
            background-image: url('../assets/img/close-modal.png');
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            cursor: pointer;
            top: 40px;
            left: 37px;
            width: 45px;
            height: 45px;
            @media screen and (max-width: 420px) {
                top: 25px;
                left: 25px;
            }
        }
        .picture-modal{
            width: 160px;
            height: 160px;
            background-repeat: no-repeat;
            background-size: cover;
            margin: auto;
            border-radius: 22px;
            background-position: center;
            position: relative;
            &:after{
                content: '';
                background-image: url('../assets/img/valid.png');
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                bottom: 18px;
                right: 15px;
                width: 45px;
                height: 45px;
            }
        }
        .title-modal{
            color: $darkGrey;
            margin-top: 40px;
            font-weight: bold;
            font-size: 31px;
        }
        .text-modal{
            color: $grey;
            font-size: 23px;
            margin-top: 15px;
            line-height: 25px;
        }
    }
}

.fr-lang{
    .page-commande{
        .btn-submit{
            width: 420px;
            @media screen and (max-width: 420px) {
                width: 300px;
            }
        }
    }
}