.btn{
    position: relative;
    border-radius: 30px;
    cursor: pointer;
    z-index: 10;
    span{
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-transform: uppercase;
        text-align: center;
    }
    &.yellow{
        background-color: $yellow;
        span{
            color: $white;
            font-weight: bold;
        }
    }
}