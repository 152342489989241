.modal-choice-menu-item{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $whitetransp;
    transition: 0.5s;
    padding-bottom: 5px;
    color: $greyTextModal;
    &.hide-modal{
        display: none;
        opacity: 0;
    }
    &.show-modal{
        display: block;
        opacity: 1;
    }
    .cross-close{
        background-image: url("../assets/img/cross_close.png");
        width: 20px;
        height: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        cursor: pointer;
        z-index: 15;
        right: 30px;
        top: 35px;
        @media screen and (max-width: 420px) {
            right: 25px;
            top: 25px;
        }
    }
    .block-menu-item{
        display: flex;
    }
    .picture-menu-item{
        width: 0px;
        height: 375px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @media screen and (min-width: 850px) {
            width: 283px;
        }
    }
    .info-menu-item{
        padding: 22px 0 0 50px;
        position: relative;
        @media screen and (max-width: 420px) {
            padding: 22px 0 0 10px;
        }
    }
    .title-menu-item{
        color: $greyTextModal;
        font-size: 32px;
        padding: 0;
        max-width: 530px;
        @media screen and (max-width: 420px) {
            font-size: 27px;
            padding: 0 25px 0 0;
        }        
        @media screen and (min-width: 850px) {
            max-width: 350px;
        }
        @media screen and (min-width: 1020px) {
            max-width: 490px;
        }
        @media screen and (min-width: 950px) {
            max-width: 400px;
        }
    }
    .block-delivery{
        display: flex;
        align-items: center;
        margin-top: 25px;
        .picto{
            background-image: url("../assets/img/picto_delivery.png");
            width: 35px;
            height: 35px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
        .info-delivery{
            margin-left: 10px;
            font-size: 20px;
            font-weight: bold;
        }
    }
    .block-text{
        width: 530px;
        max-height: 150px;
        padding-right: 20px;
        @media screen and (max-width: 420px) {
            width: 300px;
        }
        @media screen and (min-width: 850px) {
            width: 420px;
        }
        @media screen and (min-width: 1000px) {
            width: 500px;
        }
        @media screen and (min-width: 1200px) {
            width: 620px;
        }
    }
    .description-menu-item{
        font-size: 20px;
    }
    .allergen-menu-item{
        margin-top: 25px;
        font-size: 20px;
    }
    .btn-order-modal-menu-item{
        font-size: 22px;
        width: 135px;
        height: 50px;
        position: absolute;
        bottom: 32px;
        left: 56px;
    }
}

.fr-lang{
    .modal-choice-menu-item{
        .btn-order-modal-menu-item{
            width: 175px;
        }
    }
}