.header-choice-block{
    color: $white;
    .header-choice-bkg{
        width: 100%;
        height: 275px;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        background-position: center;
        @media screen and (max-width: 420px) {
            height: 200px;
        }        
        @media screen and (min-width: $mid-tablet) {
            height: 325px;
        }
    }
    .title-block{
        position: absolute;
        z-index: 5;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        text-transform: uppercase;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 5px;
    }
    .goback-arrow{
        height: 45px;
        width: 45px;
        background-image: url("../assets/img/arrow_back.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 90px 0 50px;
        cursor: pointer;
    }
    .title{
        font-size: 53px;
        font-weight: bold;
        text-align: center;
        @media screen and (max-width: 420px) {
            font-size: 30px;
        }
        @media screen and (min-width: $mid-tablet) {
        }
    }
}