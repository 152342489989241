.menu-breakfasts-block{
    padding: 10px 0 40px 0;
    .menu-breakfasts{
     margin-top: 40px;
    }
    .title-menu-breakfasts{
        font-size: 32px;
        font-family: $fontHappi;
        color: $darkGrey;
    }
    .menu-block{
        margin-top: 20px;
        position: relative;
        // display: flex;
        // flex-direction: row;
    }
    .menu-item-block{
        max-width: 350px;
        height: 300px;
        padding: 36px 0px;
        margin-right: 15px;
        position: relative;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @media screen and (min-width: 901px) {
            max-width: 270px;            
        }
        @media screen and (min-width: 1020px) {
            max-width: 400px;            
        }
        @media screen and (min-width: 1401px) {
            max-width: 300px;            
        }
    }
    .title-menu-item{
        color: $white;
        font-weight: bold;
        font-size: 36px;
        padding: 0 25px;
        @media screen and (max-width: 420px) {
            font-size: 24px;
            padding: 0 5px;
        }
        @media screen and (min-width: 901px) {
            font-size: 28px;
        }
        @media screen and (min-width: 1020px) {
            font-size: 32px;
        }
    }
    .btn-order-menu-item{
        font-size: 22px;
        width: 135px;
        height: 47px;
        position: absolute;
        bottom: 25px;
        right: 27px;
        @media screen and (max-width: 420px) {
            height: 40px;
            font-size: 20px;
            right: 5px;
        }
    }
    .slick-track{
        margin-left: 0;
    }
    .slick-slide{
        outline: none;
    }
    .slick-dots {
        bottom: -40px;
    }
    .modal-restrict{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $blacktransp;
        transition: 0.5s;
        padding-bottom: 5px;
        color: $greyTextModal;
        z-index: 99;
        &.hide-modal{
            display: none;
            opacity: 0;
        }
        &.show-modal{
            display: block;
            opacity: 1;
        }
        .content-modal{
            max-width: 365px;
            background-color: $white;
            border-radius: 18px;
            position: fixed;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin: 0 auto;
            padding: 70px 100px 50px;
            text-align: center;
        }
        .title{
            color: $darkGrey;
            font-weight: bold;
            font-size: 31px;
        }
        .text{
            color: $grey;
            font-size: 23px;
            margin-top: 15px;
            line-height: 25px;
        }
        .btn-close{
            width: 200px;
            height: 55px;
            font-size: 28px;
            margin: auto;
            margin-top: 30px;
        }
    }
}

.fr-lang{
    .menu-breakfasts-block{
        .btn-order-menu-item{
            width: 170px;
            @media screen and (max-width: 420px) {
                width: 140px;
            }
        }
    }
}