html{

}

body{
    margin: 0;
    padding: 0;
    font-family:  $font;
}

.loader-block{
    &.show{
        display: block;
    }
    &.hide{
        display: none;
    }
    width: 100%;    
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    transition: 0.5s;
    padding-bottom: 5px;
    color: $greyTextModal;
    z-index: 9999;
    .content-loader{
        background-color: $white;
        width: 100px;
        height: 100px;
        position: absolute;
        top: 43%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        border-radius: 300px;
        animation: bounce 0.5s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
    }
    .loader{
        background-image: url('../assets/img/loader.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 80px;
        height: 80px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}

@keyframes bounce {
    from{
        transform: scale(1);
    }
    to{
        transform: scale(1.2);        
    }
  }

