.form-contact-block{
    padding: 102px 0px 40px 0px;
    @media screen and (max-width: 420px) {
        overflow: hidden;
        padding: 50px 10px 40px 10px;
    }
    .title-form-contact{
        font-size: 31px;
        font-weight: bold;
        color: $darkGrey;
        padding-left: 65px;
        letter-spacing: 1px;
        @media screen and (max-width: 420px) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .form-contact{
        display: flex;
        max-width: 640px;
        margin: auto;
        margin-top: 120px;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 420px) {
            margin-top: 60px;
        }
    }
    .room-input-block{
        width: 100%;
        margin-bottom: 75px;
        input{
            margin-bottom: 10px;
        }
        label{
            color: $grey;
            font-size: 13px;
        }
    }
    .input-form{
        width: 100%;
        border: none;
        border-bottom: 1px solid $grey;
        background: none;
        font-size: 20px;
        letter-spacing: 1.9px;    
        color: $grey;
        outline: none;
        padding-bottom: 10px;
        margin-bottom: 75px;
        @media screen and (max-width: 420px) {
            margin-bottom: 30px;
        }
        &::placeholder {
            color: $grey;
            opacity: 1; /* Firefox */
        }
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
           color: $grey;
        }
        &::-ms-input-placeholder { /* Microsoft Edge */
           color: $grey;
        }
        &.phone{
            margin-bottom: 70px;
        }
    }
    .block-separator{
        margin: 0px 0 60px 0;
        width: 190px;
        span{
            color: $grey;
            font-size: 25px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:before, 
            &:after{
                content: "";
                background-color: $grey;
                width: 60px;
                height: 1.5px;

            }
        }
    }
    .submit-block{
        margin-top: 85px;
        @media screen and (max-width: 420px) {
            margin-top: 50px;
        }
    }
    .btn-submit{
        width: 160px;
        height: 55px;
        font-size: 28px;
        margin: auto;
    }
    .dots{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-top: 80px;
        @media screen and (max-width: 420px) {
            margin-top: 50px;
        }
        div{
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background-color: $grey;
            &:nth-child(1){
                margin-left: -5px;
            }
            &:nth-child(2){
                background-color: $yellow;
                margin-left: 6px;
            }
        }
    }
}